<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-14 10:34:21
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-02-10 11:45:07
 * Description tableTemplate组件:
-->
<template>
  <div class="enterpriseInfo">
    <div>
      <tableTemplate
        class="table"
        :searchSelectList="enterpriseList"
        @searchFormType="searchFormType"
        @searchBut="searchBut"
        addName="新建员工"
        :searchListInfo="searchListInfo"
        :loading="loading"
        :data="tableData"
        :columns="columns"
        @addClick="addClick"
        :seceltModel="seceltModel"
      >
        <template slot="avatar" slot-scope="scope">
          <div slot="avatar" class="operation">
            <span @click="staffInfo(scope.data.row)">详情</span>
            <span @click="eait(scope.data.row)">编辑</span>
            <span @click="delect(scope.data.row)">删除</span>
          </div>
        </template>
      </tableTemplate>
      <page @changePage="eventPage" @changeSize="eventPage" class="pageStyle" :pagination="pagination" />
      <!-- 添加 -->
      <el-dialog :title="titles" :visible.sync="dialogVisible" width="30%">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="80px">
          <el-form-item label="员工名称" prop="name">
            <el-input :disabled="staffInfoState" v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="员工岗位" prop="post">
            <el-select :disabled="staffInfoState" v-model="addForm.post" placeholder="请选择员工岗位">
              <el-option v-for="self in enterpriseList" :key="self.id" :label="self.name" :value="self.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="员工电话" prop="tel">
            <el-input :disabled="staffInfoState" v-model="addForm.tel"></el-input>
          </el-form-item>
          <!-- <el-form-item label="生日" prop="dateOfBirth">
            <el-date-picker :disabled="staffInfoState" value-format="yyyy-MM-dd HH:mm:ss" v-model="addForm.dateOfBirth" type="date" :picker-options="pickerOptions0" placeholder="选择日期">
            </el-date-picker>
          </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer" v-if="!staffInfoState">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button class="butColor" size="small" type="primary" @click="saveFrom(addForm, 'addForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import tableTemplate from '@/components/soureUnit//tableTemplate.vue';
import page from '@/components/page.vue';
import { staff, checkAge } from '@/utils/required.js';
export default {
  components: {
    tableTemplate,
    page,
  },
  data() {
    return {
      columns: [
        { label: '序号', typeIndex:'typeIndex', align: 'center'},
        { label: '员工姓名', prop: 'name', align: 'center' },
        { label: '岗位', prop: 'post', align: 'center' },
        { label: '联系电话', prop: 'tel', align: 'center' },
        // { label: '员工生日', prop: 'dateOfBirth', align: 'center' },
        // { label: '员工生日', prop: 'dateOfBirth', align: 'center', },
        { label: '操作', prop: 'avatar', align: 'center', __slotName: 'avatar', width: 150 },
      ],
      pickerOptions0: {
        disabledDate(time) {
         return time.getTime() > Date.now() - 8.64e6
        },
      },
      tableData: [],
      addForm: {},
      dialogVisible: false,
      titles: '新建',
      pagination: {
        totalRow: 0,
        page: 1,
        size: 8,
      },
      rules: staff,
      seceltModel: true,
      enterpriseList: [
        { name: '管理人员', value: 0 },
        { name: '生产人员', value: 1 },
        { name: '销售人员', value: 2 },
        { name: '质检人员', value: 3 },
      ],
      loading: true,
      searchListInfo: {
        name: '员工姓名',
        type: '岗位',
      },
      staffInfoState: false,
    };
  },
  mounted() {
    this.requestLsit();
  },
  methods: {
    addClick() {
      this.dialogVisible = true;
      this.titles = '新建';
      this.staffInfoState = false;
      if (this.$refs['addForm'] !== undefined) {
        this.$nextTick(() => {
          this.$refs['addForm'].resetFields();
          this.addForm = {};
        });
      }
    },
    /**
     * @description: loadingg 关闭
     * @param {*}
     */

    exitLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    /**
     * @description:  请求数据/搜索
     * @param {*} name 关键字搜索
     * @param {*} enterpriseType 岗位搜索
     */

    requestLsit(name, post) {
      this.$get('/enterpriseManage/querryStaff', { page: this.pagination.page, size: this.pagination.size, name: name || '', post: post || '' })
        .then((res) => {
          if (res.data.state == 'success') {
            let { datas } = res.data;
            this.pagination.totalRow = res.data.count;
            this.tableData = datas;
            this.exitLoading();
          }
        })
        .catch((err) => {
          this.exitLoading();
          throw err;
        });
    },
    /**
     * @description:  保存
     * @param {*} data 数据
     * @param {*} dom 元素
     */

    saveFrom(data, dom) {
      let url;
      if (this.titles == '新建') {
        url = '/enterpriseManage/addStaff';
      } else if (this.titles == '修改') {
        url = '/enterpriseManage/updateStaff';
        data.staffId = data.id;
        delete data.id;
      }
      this.$refs[dom].validate((valid) => {
        if (valid) {
          this.$get(url, data).then((res) => {
            // //console.log(res, '接口数据');
            if (res.data.state == 'success') {
              this.dialogVisible = false;
              this.$message.success(res.data.msg);
              this.requestLsit();
            }
          });
        }
      });
    },
    /**
     * @description:  修改
     * @param {*} data
     */

    eait(data) {
      this.titles = '修改';
      this.dialogVisible = true;
      this.staffInfoState = false;
      this.$nextTick(() => {
        this.addForm = JSON.parse(JSON.stringify(data));
      });
    },
    staffInfo(data) {
      this.titles = '详情';
      this.dialogVisible = true;
      this.staffInfoState = true;
      this.$nextTick(() => {
        this.addForm = JSON.parse(JSON.stringify(data));
      });
    },
    /**
     * @description: 删除
     * @param {*} data
     */

    delect(data) {
      this.$confirm(`此操作将永久删除 ${data.name} 信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$get('/enterpriseManage/delStaff', { staffId: data.id }).then((res) => {
            if (res.data.state == 'success') {
              this.requestLsit();
              this.loading = true;
              this.$message.success(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    //
    searchBut(data) {
      this.pagination.page = 1;
      this.pagination.size = 8;
      this.requestLsit(data.name);
    },
    // refresh(){
    //   this.loading = true;
    //   this.searchListInfo = {
    //     name: '',
    //     type: '',
    //   },
    //   this.requestLsit();
    // },
    searchFormType(val) {
      this.requestLsit('', val);
    },
    eventPage() {
      this.requestLsit();
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/style/colorPublic";
.butColor{
    background: @but_color;
}
.operation {
  display: flex;
  justify-content: space-around;
  span {
    cursor: pointer;
  }
}
.enterpriseInfo /deep/ .el-dialog__title {
  font-size: 1.5vh;
}
.table {
  height: 82vh;
  overflow-y: auto;
}
.enterpriseInfo {
  background: #fff;
  padding: 2vh;
  box-sizing: border-box;
}
@media screen and (max-width: 1500px) {
  .table {
    height: 80vh;
    overflow-y: auto;
  }
}
</style>
